import { faBuilding, faMapMarkedAlt, faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import AddressImage from '../AddressImage';
import CompanyInformationImage from '../CompanyInformationImage';
import KycImage from '../KycImage';
import PersonalInformationImage from '../PersonalInformationImage';
import { Section } from '../Section/section';
import styles from './FeaturesSection.module.scss';

const features = [
  {
    title: 'Personal Information',
    icon: faUser,
    image: PersonalInformationImage,
    description: "Get your customers' personal information (name, birth date, contact information...).",
  },
  {
    title: 'Address lookup',
    icon: faMapMarkedAlt,
    image: AddressImage,
    description: 'Collect their address instantly with an Algolia-powered address search.',
  },
  {
    title: 'Kyc',
    icon: faUserCheck,
    image: KycImage,
    description: 'Verify their identity with a video-based verification engine.',
  },
  {
    title: 'Company information',
    icon: faBuilding,
    image: CompanyInformationImage,
    description: 'Gather all information about their company at once.',
  },
];
const initialImage = features[0].image;

const FeaturesSection = () => {
  const [image, setImage] = useState(initialImage);

  const handleMouseEnter = (image: () => JSX.Element) => () => {
    setImage(image);
  };

  return (
    <Section
      title="Choose the best pages for your onboarding"
      description="Get the most information with minimum typing effort from your customers"
    >
      <div className={styles.content}>
        <div className={styles.leftColumn}>{image}</div>
        <div className={styles.rightColumn}>
          {features.map(feature => (
            <button onMouseEnter={handleMouseEnter(feature.image)} className={styles.feature} key={feature.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={feature.icon} />
              </div>
              <div className={styles.featureText}>
                <div className={styles.title}>{feature.title}</div>
                <div className={styles.description}>{feature.description}</div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default FeaturesSection;
