import React from 'react';

import styles from './section.module.scss';

interface Props {
  title: string;
  description: string;
  children?: JSX.Element;
}

export const Section = ({ title, description, children }: Props) => (
  <>
    <h2>{title}</h2>
    <h5>{description}</h5>
    <div className={styles.content}>{children}</div>
  </>
);
