import React from 'react';

import ContactButton from '../ContactButton/ContactButton';
import styles from './DemoRequestSection.module.scss';

const DemoRequestSection = () => (
  <div id="demo" className={styles.demoRequestSection}>
    <div className={styles.content}>
      <div className={styles.title}>Request a demo</div>
      <div className={styles.description}>
        To see how Fuse can help increase your conversion in record time, get in touch.
      </div>
      <ContactButton />
    </div>
  </div>
);

export default DemoRequestSection;
