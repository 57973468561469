import React from 'react';

import styles from './ContactButton.module.scss';

const ContactButton = () => (
  <div className={styles.formWrapper}>
    <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      <div className={styles.contactForm}>
        <input name="email" className={styles.contactFormInput} type="email" placeholder="youremail@mail.com" />
        <button type="submit" className={styles.contactSubmitButton}>
          Book a demo
        </button>
      </div>
    </form>
  </div>
);

export default ContactButton;
