import React from 'react';

import ContactButton from '../ContactButton/ContactButton';
import OnboardingMainImage from '../OnboardingMainImage';
import styles from './presentation.module.scss';

export const Presentation = () => (
  <div className={styles.content}>
    <h1>Generate your customer onboarding in an hour.</h1>
    <h3>Convert your leads into clients</h3>
    <ContactButton />
    <div className={styles.imageWrapper}>
      <OnboardingMainImage />
    </div>
  </div>
);

export default Presentation;
