import React from 'react';

import SipiosWhiteLogoImage from '../SipiosWhiteLogoImage';
import styles from './footer.module.scss';

const links = [
  {
    label: 'Equipe',
    url: 'https://www.sipios.com/meet-the-team',
  },
  {
    label: 'Nous rejoindre',
    url: 'https://www.sipios.com/carriere',
  },
  {
    label: 'Contact',
    url: 'https://www.sipios.com/contact',
  },
];

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.leftColumn}>
      <div className={styles.logo}>
        <SipiosWhiteLogoImage />
      </div>
      <div className={styles.legal}>Copyright © 2019 Sipios. Tous droits réservés.</div>
      <div className={styles.contact}>+33 1 53 24 53 23</div>
    </div>
    <div className={styles.rightColumn}>
      <div className={styles.sipios}>Sipios</div>
      {links.map(link => (
        <a key={link.label} className={styles.link} href={link.url} target="_blank">
          {link.label}
        </a>
      ))}
    </div>
  </footer>
);

export default Footer;
