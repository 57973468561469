import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import styles, { header } from './header.module.scss';

const sections = [
  {
    label: 'Features',
    tag: '#features',
  },
  {
    label: 'Benefits',
    tag: '#benefits',
  },
  {
    label: 'Solution',
    tag: '#solution',
  },
  {
    label: 'Demo',
    tag: '#demo',
  },
];

const Header = ({ siteTitle }: { siteTitle: string }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', function () {
      if (window.pageYOffset === 0) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
    });
  }, []);


  const handleClick = () => setIsOpened(prevState => !prevState);
  const handleTitleClick = () => isOpened && setIsOpened(false);

  const sectionsStateStyle = isOpened ? styles.menuOpened : styles.menuClosed;
  const headerStyle = `${styles.header} ${scrolling ? styles.scrolling : ''}`;

  return (
    <header className={headerStyle}>
      <div className={styles.mainLink}>
        <a href="#product" className={styles.title} onClick={handleTitleClick}>
          {siteTitle}
        </a>
        <button className={styles.toggleButton} onClick={handleClick}>
          <FontAwesomeIcon className={styles.toggleIcon} icon={faBars} />
        </button>
      </div>
      <div className={`${styles.sections} ${sectionsStateStyle}`}>
        {sections.map(section => (
          <a key={section.label} href={section.tag} onClick={handleClick} className={styles.link}>
            {section.label}
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header;
