import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import DemoRequestSection from '../DemoRequestSection/DemoRequestSection';
import ExpertiseSection from '../ExpertiseSection/ExpertiseSection';
import FeaturesSection from '../FeaturesSection/FeaturesSection';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import Presentation from '../Presentation/presentation';
import SolutionSection from '../SolutionSection/SolutionSection';
import styles, { section } from './layout.module.scss';

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

const sections: { tag: string; Component: () => JSX.Element }[] = [
  {
    tag: 'product',
    Component: Presentation,
  },
  { tag: 'features', Component: FeaturesSection },
  { tag: 'benefits', Component: ExpertiseSection },
  { tag: 'solution', Component: SolutionSection },
];

const Layout = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        {sections.map(({ tag, Component }, index) => {
          const className = index % 2 === 0 ? 'evenSection' : 'oddSection';

          return (
            <section
              key={tag}
              className={styles[className]}
            >
              <div className={styles.section}>
                <div className={styles.sectionContent}>
                  <a id={tag}>&nbsp;</a>
                  {<Component />}
                </div>
              </div>
            </section>
          );
        })}
        <DemoRequestSection />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
