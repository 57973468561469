import React from 'react';

import FinancialDataImage from '../FinancialDataImage';
import { Section } from '../Section/section';
import styles from './ExpertiseSection.module.scss';

const ExpertiseSection = () => (
  <Section title="Increase your revenue by 3%" description="For each point of NPS earned on your onboarding">
    <div className={styles.expertiseSection}>
      <div>
        <FinancialDataImage />
      </div>
      <div className={styles.expertiseSectionContent}>
        <p>
          Financial institutions complete customers onboardings and KYC processes in around 12 weeks on average. It’s
          still a process that is often accomplished manually and remains complex.
        </p>
        <p>
          Yet, the information gathering process establishes the customer relationship and builds their trust in the
          organization.
        </p>
        <p>
          A McKinsey study indicates that for every one-point increase in customer onboarding satisfaction on a Net
          Promoter Score -NPS- on a scale from one to ten, there is a 3% increase in customer revenue. A raise from 5 to
          8 points out of 10 can thus increase revenues by 9%!
        </p>
      </div>
    </div>
  </Section>
);

export default ExpertiseSection;
