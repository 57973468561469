import React from 'react';

import { Section } from '../Section/section';
import SecurityImage from '../SecurityImage';
import styles from './SolutionSection.module.scss';

const SolutionSection = () => (
  <Section
    title="100% integrated in your IT"
    description="The onboarding integrates fully into your own application. Keep your customers data safe in your own servers."
  >
    <div className={styles.solutionSection}>
      <div className={styles.solutionSectionContent}>
        <p>Softwares that offer onboarding and KYC processes usually are SaaS solutions that aren’t approved by ITs.</p>
        <p>
          Our on-premise approach using a code generator allows you to store your customers' data and the application
          code exclusively in your servers.
        </p>
      </div>
      <div>
        <SecurityImage />
      </div>
    </div>
  </Section>
);

export default SolutionSection;
